import React from "react"
import { Link, graphql } from "gatsby"
import { css } from "@emotion/core"
import styled from "@emotion/styled"
import Img from "gatsby-image"

import SEO from "../components/seo"
import Layout from "../components/layout"

export default ({ data }) => {
  const post = data.markdownRemark

  const headingBase = css`
    margin: 0;
    color: #1a645c;
  `

  const Container = styled.div`
    @media only screen and (min-width: 600px) {
      display: flex;
    }
  `

  return (
    <Layout>
      <SEO title={post.frontmatter.title} />
      <div
        css={css`
          margin: 0 auto;
          max-width: 1280px;
          padding: 0 1.0875rem;
        `}
      >
        <div
          css={css`
            padding: 1rem 0 0;
          `}
        >
          <Link
            css={css`
              color: #1a645c;
            `}
            to="/"
          >
            Home
          </Link>
        </div>
        <div
          css={css`
            padding: 4rem 0 2rem;
          `}
        >
          <h2
            css={[
              headingBase,
              css`
                font-weight: normal;
                font-size: 1.5em;
              `,
            ]}
          >
            {post.frontmatter.company}
          </h2>
          <h1
            css={[
              headingBase,
              css`
                font-size: 2.5em;
              `,
            ]}
          >
            {post.frontmatter.title}
          </h1>
        </div>
        <Container>
          <div
            css={css`
              flex: 0 0 350px;
              padding-right: 2.5rem;
            `}
            dangerouslySetInnerHTML={{ __html: post.html }}
          />
          <div
            css={css`
              flex: 1 1 auto;
            `}
          >
            {post.frontmatter.attachments.map(a => (
              <div
                css={css`
                  margin-bottom: 1rem;
                  border: 1px solid #ddd;
                  /* border-right: none; */
                `}
              >
                <Img fluid={a.childImageSharp.fluid} />
              </div>
            ))}
          </div>
        </Container>
      </div>
    </Layout>
  )
}

export const query = graphql`
  query($slug: String!) {
    markdownRemark(fields: { slug: { eq: $slug } }) {
      html
      frontmatter {
        company
        title
        attachments {
          childImageSharp {
            fluid {
              ...GatsbyImageSharpFluid
            }
          }
        }
      }
    }
  }
`
